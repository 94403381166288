define("discourse/plugins/discourse-saved-searches-extension/discourse/templates/connectors/search-menu-results-bottom/search-connector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldRender}}
    <ServiceSearchExtension />
  {{/if}}
  */
  {
    "id": "vqjzZyo7",
    "block": "[[[41,[30,0,[\"shouldRender\"]],[[[1,\"  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"service-search-extension\"]]",
    "moduleName": "discourse/plugins/discourse-saved-searches-extension/discourse/templates/connectors/search-menu-results-bottom/search-connector.hbs",
    "isStrictMode": false
  });
});