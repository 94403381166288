define("discourse/plugins/discourse-saved-searches-extension/discourse/connectors/search-menu-results-bottom/search-connector", ["exports", "@ember/service", "@glimmer/component"], function (_exports, _service, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class _default extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get shouldRender() {
      return this.currentUser?.can_use_saved_searches;
    }
  }
  _exports.default = _default;
});